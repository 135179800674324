import '../scss/Loading.scss';

const Loading = () => {
  return (
    <div className="lds-ring">
      <div>

      </div>
      <div>

      </div>
      <div>

      </div>
      <div>

      </div>
    </div>
  )
}

export default Loading;